<template>
  <Dialog :name="name" width="475px" class="add-timeslot" @close="beforeClose">
    <template #title>
      <div>Добавление авто</div>
    </template>
    <div class="w-100p">
      <IqTitle class-name="mobileHeader">Тайм слот: в порядке очереди</IqTitle>
    </div>
    <el-form
      ref="formAddTimeslot"
      :model="formAddTimeslot"
      :rules="rules"
      label-width="175px"
      class="form-add-timeslot-hand"
      @submit.native.prevent
    >
      <div class="df jc-fs ai-e m-b-20">
        <IqInputSearchForm
          v-model="formAddTimeslot.plate_truck"
          label="Номер авто"
          label-width="175px"
          input-width="265px"
          prop="plate_truck"
          :rules="rules.plate_truck"
          :disabled="isLoadingPostTimeslots"
          :loading="isLoadingPostTimeslots"
          @keydown.native.enter="handleCloseDialog"
        />
      </div>
      <div class="m-b-16">
        <IqSelectForm
          v-model="formAddTimeslot.type"
          label="Тип авто"
          prop="type"
          :rules="rules.type"
          :options="autoTypeRequired"
          key-label="title"
          key-value="val"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />
      </div>
      <div class="m-b-16">
        <IqInputForm
          v-model="formAddTimeslot.plate_trailer"
          :rules="rules.plate_trailer"
          prop="plate_trailer"
          label="Номер прицепа"
          label-width="281px"
          input-width="265px"
        />
      </div>
      <div class="m-b-16">
        <IqSelectForm
          v-model="formAddTimeslot.truck_spindles_count"
          label="Количество осей"
          prop="truck_spindles_count"
          :rules="rules.truck_spindles_count"
          :options="autoAxle"
          key-label="name"
          key-value="val"
          input-width="265px"
          :is-super-important-label-width="true"
        />
      </div>

      <div class="m-b-16">
        <IqSelectForm
          v-model="formAddTimeslot.culture_id"
          label="Культура"
          prop="culture_id"
          :rules="rules.culture_id"
          :options="cultureList"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />
      </div>

      <div class="m-b-16">
        <IqSelectForm
          v-model="formAddTimeslot.exporter_id"
          label="Экспортер"
          prop="exporter_id"
          :rules="rules.exporter_id"
          :options="exportersList"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :disabled="isDisabledExporters"
          :is-super-important-label-width="true"
        />
      </div>

      <div v-if="isTerminalRequireInn" class="df jc-fs ai-e m-b-20">
        <IqInputSearchForm
          v-model="formAddTimeslot.inn"
          prop="inn"
          :rules="rules.innRule"
          label="ИНН поставщика"
          label-width="175px"
          input-width="265px"
          :disabled="isLoadingPostTimeslots"
          :loading="isLoadingPostTimeslots"
          :message="
            formAddTimeslot.common_supplier_id ? formAddTimeslot.innName : ''
          "
          @search="fetchCommonSuppliersCheckInn(formAddTimeslot.inn)"
        />
      </div>
      <div class="m-b-16">
        <IqInputForm
          v-model="formAddTimeslot.status"
          label="Статус"
          label-width="281px"
          input-width="265px"
          disabled
        />
      </div>

      <div class="m-b-16">
        <IqInputForm
          v-model="formAddTimeslot.phone"
          v-mask="'+7 ### ###-##-##'"
          label="Телефон водителя"
          prop="phone"
          label-width="281px"
          input-width="265px"
          :rules="rules.phone"
        />
      </div>

      <div class="m-b-16">
        <IqInputForm
          v-model="formAddTimeslot.driver_name"
          label="ФИО водителя"
          prop="name"
          label-width="281px"
          input-width="265px"
          :rules="rules.driver_name"
        />
      </div>
      <div class="m-b-20">
        <IqSelectForm
          v-model="formAddTimeslot.detach"
          label="Проезд"
          prop="detach"
          :rules="rules.detach"
          :options="[
            { id: 0, name: 'Разрешен' },
            { id: 1, name: 'Отказан' },
          ]"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />
      </div>

      <div class="form-add-timeslot-hand__timeslot-wrap-btn">
        <IqButton
          :loading="isLoadingPostTimeslots"
          class="w-100p"
          @onClick="handleAddNewTimeslot"
        >
          Добавить тайм слот
        </IqButton>
      </div>
    </el-form>
  </Dialog>
</template>

<script>
import {
  FETCH_COMMON_SUPPLIERS_CHECK_INN,
  FETCH_TIMESLOT,
  GET_COMMON_SUPPLIERS_CHECK_INN,
  GET_IS_LOADING_POST_TIMESLOTS,
  POST_HAND_TIMESLOT,
} from '@/views/manager/store/actions'
import {
  GET_CULTURE_FROM_STATE,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import {
  GET_TERMINAL_CURRENT_ID_SLUG,
  GET_TERMINAL_CURRENT_REQUIRE_INN,
} from '@/store/actions'
import { STEP_MANAGER_ADD_TIMESLOT_HAND } from '@/constants/dialogs'
import { autoAxle, autoTypeRequired } from '@/constants/auto-type'
import { mapActions, mapGetters } from 'vuex'
import { onlyTruckNumValidator } from '@/core'
import { ruleFormAddTimeslot } from './data/dialogAddTimeslotHand'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqInputForm from '@/UI/form/input/IqInputForm'
import IqInputSearchForm from '@/UI/form/input/IqInputSearchForm'
import IqSelectForm from '@/UI/select/IqSelectForm'
import IqTitle from '@/UI/typography/IqTitle'

export default {
  name: 'StepDialogAddTimeslotHand',
  components: {
    IqButton,
    IqSelectForm,
    IqInputForm,
    IqTitle,
    Dialog,
    IqInputSearchForm,
  },
  data() {
    // правило для валидации поля ИНН поставщика
    const innRule = [
      {
        required: true,
        validator: (rule, value, callback) => {
          // применяетя только при наличии у терминала поля require_inn
          if (!this.isTerminalRequireInn) {
            return callback()
          }
          if (!value.length) {
            return callback(new Error('Укажите ИНН поставщика'))
          }
          if (!this.formAddTimeslot.common_supplier_id) {
            return callback(new Error('Укажите корректный ИНН поставщика'))
          }

          return callback()
        },
        trigger: 'submit',
      },
    ]

    return {
      autoAxle,
      name: STEP_MANAGER_ADD_TIMESLOT_HAND,
      cultureList: [],
      formAddTimeslot: {
        plate_trailer: '',
        innName: '',
        inn: '',
        common_supplier_id: null,
        truck_spindles_count: null,

        plate_truck: '',
        exporter_id: '',
        culture_id: '',
        status: 'На парковке',
        phone: '',
        type: null,
        detach: null,
        driver_name: '',
      },
      rules: { ...ruleFormAddTimeslot, innRule },
      autoTypeRequired,
    }
  },
  computed: {
    ...mapGetters({
      isLoadingPostTimeslots: GET_IS_LOADING_POST_TIMESLOTS,
      getCultures: GET_CULTURE_FROM_STATE,
      exportersList: GET_EXPORTERS_ALL,
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      getInn: GET_COMMON_SUPPLIERS_CHECK_INN,
      isTerminalRequireInn: GET_TERMINAL_CURRENT_REQUIRE_INN,
    }),
    dialogData() {
      return this.getDialog(this.name)?.data
    },
    isDisabledExporters() {
      return this.exportersList.length === 1
    },
  },
  watch: {
    getInn(val) {
      if (val) {
        this.formAddTimeslot.innName = val.name
        this.formAddTimeslot.common_supplier_id = val.id
      }
      this.$refs.formAddTimeslot.clearValidate('inn')
    },

    getCultures: {
      handler(value) {
        if (value) {
          this.cultureList = value.map(item => ({
            id: item.id,
            unload_id: item.unload_id,
            name: `${item.name} ${
              item.harvest_year ? item.harvest_year + ' года' : ''
            }`,
            harvest_year: `${item.harvest_year}`,
          }))
        }
      },
      deep: true,
      immediate: true,
    },
    dialogData(val) {
      if (val.plate_truck) {
        this.setFormFields('', val.plate_truck)
      }

      if (this.exportersList.length === 1) {
        this.formAddTimeslot.exporter_id = this.exportersList[0].id
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTimeslot: FETCH_TIMESLOT,
      createTimeslot: POST_HAND_TIMESLOT,
      fetchCommonSuppliersCheckInn: FETCH_COMMON_SUPPLIERS_CHECK_INN,
    }),
    handleCloseDialog() {
      this.$refs.formAddTimeslot.validateField('plate_truck', () => {
        if (
          this.formAddTimeslot.plate_truck !== '' &&
          !onlyTruckNumValidator(this.formAddTimeslot.plate_truck)
        ) {
          this.fetchTimeslot({
            plate_truck: this.formAddTimeslot.plate_truck,
            nameDialog: this.name,
          })
        }
      })
    },
    handleAddNewTimeslot() {
      this.$refs.formAddTimeslot.validate(valid => {
        if (valid) {
          const requestData = {
            name: this.formAddTimeslot.driver_name,
            plate_trailer: this.formAddTimeslot.plate_trailer,
            innName: this.formAddTimeslot.innName,
            truck_spindles_count: this.formAddTimeslot.truck_spindles_count,
            culture_id: this.formAddTimeslot.culture_id,
            plate_truck: this.formAddTimeslot.plate_truck,
            phone: this.formAddTimeslot.phone,
            setFormFields: this.setFormFields,
            nameDialog: this.name,
            truck_type_code: this.formAddTimeslot.type,
            detach: this.formAddTimeslot.detach,
            exporter_id: this.formAddTimeslot.exporter_id,
            common_supplier_id: this.formAddTimeslot.common_supplier_id,
          }

          this.createTimeslot(requestData)
        }
      })
    },
    setFormFields(
      culture_id = '',
      plate_truck = '',
      phone = '',
      type = null,
      detach = null,
      exporter_id = '',
      plate_trailer = '',
      innName = '',
      inn = '',
      common_supplier_id = null,
      truck_spindles_count = '',
      driver_name = '',
      name = '',
    ) {
      this.formAddTimeslot.culture_id = culture_id
      this.formAddTimeslot.driver_name = driver_name
      this.formAddTimeslot.plate_truck = plate_truck
      this.formAddTimeslot.phone = phone
      this.formAddTimeslot.type = type
      this.formAddTimeslot.detach = detach
      this.formAddTimeslot.exporter_id = exporter_id
      this.formAddTimeslot.plate_trailer = plate_trailer
      this.formAddTimeslot.innName = innName
      this.formAddTimeslot.inn = inn
      this.formAddTimeslot.common_supplier_id = common_supplier_id
      this.formAddTimeslot.truck_spindles_count = truck_spindles_count
      this.formAddTimeslot.name = name
    },
    beforeClose() {
      this.setFormFields()
      this.$refs.formAddTimeslot.clearValidate()
    },
  },
}
</script>

<style lang="sass">
.form-add-timeslot-hand

  &__left-section,
  &__right-section
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: flex-start
    flex: 0 0 50%

  &__timeslot-heading
    flex: 0 0 100%
    margin-top: 0
    margin-bottom: 16px

  &__timeslot-wrap-btn
    flex: 0 0 100%
    display: flex
    justify-content: center

  &__btn
    font-size: 28px
    cursor: pointer
    color: $color-black
    transition: all .3s ease
    padding: 0 !important
    border: none
    &:hover, &:focus
      color: $btn-orange
      background: transparent

  .el-form-item__content
    position: static

  .el-form-item
    position: relative
</style>

<style lang="sass" scoped>
:deep(.el-form-item__error)
  margin-left: 175px
</style>
